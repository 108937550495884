<template>
  <div class="container page">
    <van-nav-bar title="收款卡資訊" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="wrapper">
      <div class="add-card" @click="toBindCard()">
        <van-icon name="plus" />
        <span>新增收款卡</span>
      </div>
      <div class="bank">
          <div class="info" v-for="(item, index) in bankInfo" :key="index">
            <div class="row-content"><span class="daihao">銀行代號</span> {{item.bankinfo}}</div>
            <div class="row-content"><span class="daihao">金融卡號</span>{{item.bankid}}</div>
          </div>
      </div>
      <div class="tips">提示:請綁定大型商業銀行,如需修改,請您聯絡線上客服</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_bind:false,
      bankInfo:{},
      userInfo:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
            this.bankInfo = res.data.info
          }else {
            this.is_bind = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    toBindCard() {
      if (!this.userInfo.name) {
        this.$router.push("Setname");
        this.$toast("請設定姓名後再綁定銀行卡！");
        return true;
      }else if(!this.userInfo.paypassword){
        this.$router.push("SetPayPassword");
        this.$toast("請設定提現密碼後再綁定銀行卡！");
        return true;
      } else {
        this.$router.push({path:'/BindCard'})
      }
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 35px;
  line-height: 80px;
}

.container p{
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}
.manage-card .wrapper{
  height: calc(100% - 10px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.wrapper .add-card{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  background-color: #fff;
  height: 250px;
}
.wrapper .add-card span{
  margin-left: 10px;
  font-size: 30px;
}
.wrapper .tips {
  margin: 15px 15px;
  font-size: 25px;
  color: #979799;
}
.wrapper .bank .info {
  background: #fff;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
}

.wrapper .bank .info .row-content{
  margin: 30px 0;
  line-height: 20px;
  font-size: 30px;
}
.daihao {
  margin-right: 10px;
}
</style>

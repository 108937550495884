<template>
    <div class="container page">
      <div class="header">
        <van-nav-bar title="帳戶明細" class="nav-bar">
          <template #left>
            <van-icon name="arrow-left" color="#fff" @click="back()"/>
          </template>
        </van-nav-bar>
      </div>
      <div class="content">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <div class="card-item" v-for="(item, index) in notice" :key="index">
            <div class="card-item1 flex">
                <span>
                    <span class="titt">金額</span>
                    <span>{{ item.money }}</span>
                </span>
                <span class="lswe">
                    <span class="titt">類型</span>
                    <span>{{ item.type == 1 ? '收入' : '支出' }}</span>
                </span>
            </div>
            <div class="card-item1 memo">
                <span>
                    <span class="titt">備註</span>
                    <span>{{ item.memo }}</span>
                </span>
            </div>
            <div class="card-item1 timne">
                <span>
                    <span class="titt">時間</span>
                    <span>{{ item.create_time }}</span>
                </span>
            </div>
          </div>
          <div class="pages">
            <button class="pagebtn" @click="pageOn">上一頁</button>
            <span class="tpages">總頁數: {{ totalPage }}</span>
            <span class="tpages">目前頁: {{ page }}</span>
            <button class="pagebtn" @click="pageDown">下一頁</button>
          </div>
      </van-pull-refresh>
      </div>
    </div>
  
  </template>
  
  <script>
  export default {
    data() {
      return {
        isLoading: false,
        notice:{},
        loading: false,
        finished: false,
        page: 1,
        totalPage: 0,
      };
    },
    methods: {
      back(){
        return window.history.back();
      },
      getNoticeList(){
        this.$http({
          method: 'get',
          data: {
            page: this.page
          },
          url: 'capital'
        }).then(res=>{
          this.notice = res.data.list
          this.totalPage = res.data.totalPage
        })
      },
      pageOn() {
        this.page -= 1
        if (this.page <=1) {
            this.page = 1
        }
        this.getNoticeList()
      },
      pageDown() {
        this.page += 1
        if (this.page > this.totalPage) {
            this.page = this.totalPage
        }
        this.getNoticeList()
      },
      onRefresh() {
        setTimeout(() => {
          this.$toast("刷新成功");
          this.isLoading = false;
          this.getNoticeList();
        }, 500);
      },
    },
    created() {
      this.getNoticeList();
    }
  };
  </script>
  
  <style lang='less' scoped>
  @import "../../assets/css/base.css";
  ::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
    color: #000000;
    font-size: 35px;
  }
  
  ::v-deep .van-loading__text {
    color: #000000;
    font-size: 35px;
  }
  .container .content {
    height: calc(100% - 20px);
    overflow: auto;
  }
  .container .content .listItem{
    margin-bottom: 20px;
    padding: 20px 20px 0;
    position: relative;
    color: #000;
    background-color: #fff;
  }
  .container .content .listItem .listTitle{
    font-size: 38px;
  }
  .container .content .listItem .listContent{
    border-bottom: 2px solid #f2f2f5;
    padding: 5px 0;
    font-size: 25px;
  }
  .container .content .listItem .listTime{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 70px;
  }
  .container .content .listItem .listTime .listTimeText {
    color: #656566;
    font-size: 30px;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .card-item {
    padding: 20px;
    border-bottom: 1px solid #eee;
    background: #fff;
    box-sizing: border-box;
  }
  .titt {
    margin-right: 10px;
  }
  .timne {
    color: #a8a8a8;
    font-size: 0.8rem;
  }
  .memo {
    color: rgb(82, 82, 82);
    font-size: 0.9rem;
  }
  .lswe {
    font-size: 0.88rem;
    color: #656566;
  }
  .pages {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .pagebtn {
    border: none;
    background-color: rgb(17, 164, 209);
    color: #fff;
    border-radius: 4px;
    margin: 20px 10px;
  }
  .tpages {
    font-size: 0.88rem;
    color: #656566;
  }
  </style>
  
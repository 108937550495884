<template>
  <div class="container page">
    <van-nav-bar title="提現中心" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
      <template #right>
        <span class="nav-right" @click="$router.push({path:'/WithdrawRecord'})">提現記錄</span>
      </template>
    </van-nav-bar>
    <div class="main">
      <div class="withdrawMoney">
        <span>提現記錄</span>
        <div class="money">
          <div class="moneyNumber">
            <span class="moneyType">¥</span>
            <van-field v-model="withdraw_money" type="number" />
          </div>
          <span class="all" @click="allMoeny()">全部</span>
        </div>
        <div class="money">
          <div class="moneyNumber fdfdfd">
            <van-field v-model="bank" label="選擇銀行卡" readonly placeholder="選擇銀行卡" @click="showSelectBanks()"/>
          </div>
        </div>

        <van-popup v-model="showBank" round position="bottom" :style="{ height: '35%' }" >
          <van-picker
              show-toolbar
              :columns="bankInfo"
              @confirm="onConfirm"
              @cancel="onCancel"
          />
        </van-popup>

        <div class="information">
          <div class="description">
            <van-popover v-model="showPopover" trigger="click">
              <div class="popover-body" style="padding: 10px;">
                <p>1.單筆限額：最低{{this.withdrawRole.min}}元，最高{{this.withdrawRole.max}}元</p>
                <p>2.提現數：一天最高提現{{this.withdrawRole.num}}次</p>
                <p>3.到帳時間：一般到帳時間約5分鐘左右，最快2分鐘內到帳</p></div>
              <template #reference @click="withdrawInfo()">
                <van-icon name="info-o" />
                限額說明
              </template>
            </van-popover>
          </div>

          <div class="balance">
            <span>餘額：</span>
            <span class="number">{{ this.userInfo.money }}元</span>
          </div>
        </div>
      </div>
      <van-button class="withdraw_btn" type="default" @click="doWithdraw()">馬上提現</van-button>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopover: false,
      withdraw_money:"",
      userInfo:{},
      withdrawRole:{},
      bankInfo: [],
      showBank:false,
      bank: "",
      bank_id: 0,
    };
  },
  methods: {
    onConfirm(value) {
      this.bank = value.text
      this.bank_id = value.value
      this.showBank = false
    },
    onCancel() {
      this.showBank = false;
    },
    showSelectBanks() {
      this.showBank = true;
    },
    back(){
      return window.history.back();
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
            let data = res.data.info
            data.forEach(val => {
              const bid = val.bankid
              const firstFour = bid.slice(0, 4) // 提取前四位
              const lastFour = bid.slice(-4)    // 提取后四位
              const b = `${firstFour}****${lastFour}`
              let item = {
                value: val.id,
                text: val.bankinfo +'  '+ b
              }
              this.bankInfo.push(item)
            })
          }else {
            this.is_bind = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserWithdrawRole(){
      this.$http({
        method: 'get',
        url: 'user_get_withdraw_role'
      }).then(res=>{
        if(res.code === 200){
          this.withdrawRole = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    allMoeny(){
      this.withdraw_money = this.userInfo.money;
    },
    doWithdraw(){
      if(this.withdraw_money <= 0){
        this.$toast("請填寫正確的金額")
        return false
      } else if (this.bank_id == 0) {
        this.$toast("請選擇銀行卡號")
        return false
      } else {
        this.$http({
          method: 'post',
          data:{ money: this.withdraw_money, bank_id: this.bank_id },
          url: 'user_set_withdraw'
        }).then(res=>{
          if(res.code === 200){
            location. reload()
            this.$toast(res.msg);
            this.getUserInfo();
            this.getUserWithdrawRole();
          }else if(res.code ===401){
            this.$toast(res.msg);
          }
        })
      }
    },
    withdrawInfo(){
      this.showPopover = true;
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getUserWithdrawRole();
      this.getUserBankInfo()
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 35px;
  line-height: 80px;
}
.container p{
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}
.container .main{
  display: flex;
  flex-direction: column;
  background-color: #f2f2f5;
  height: calc(100% - 50px);
  position: relative;
}
.container .main .withdrawMoney{
  display: flex;
  flex-direction: column;
  color: #000;
  padding: 0 20px;
  white-space: nowrap;
  font-size: 35px;
  background-color: #fff;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f5;
}
.container .main .withdrawMoney .money .moneyNumber{
  font-size: 50px;
  display: flex;
  flex-direction: row;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money .all{
  color: #d10404;
}
.container .main .withdrawMoney .money .moneyNumber .van-cell {
  font-size: 50px;
  padding: 0!important;
}
.container .main .withdrawMoney .information{
  padding-bottom: 30px;
}
.container .main .withdrawMoney .information .description {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}
.container .main .withdrawMoney span{
  padding: 10px 0;
}
.container .main .withdrawMoney .information .balance .number{
  color: #d10404;
}
.withdraw_btn {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(
      90deg,#e6c3a1,#7e5678);
}

::v-deep .van-field__label {
  font-size: 0.9rem;
}
::v-deep .van-field__body {
  font-size: 0.9rem;
}
</style>

<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" title="附近約" />
		<div class="convention-item">
			<div class="item" v-for="(item,index) in list" :key="index">
                <div class="head">
                    <div class="head-img">
                        <img :src="item.img_url" class="headimg">
                    </div>
                    <div>
                        <div class="name-info">
                            <div class="name">{{ item.xuanfei_name }}</div>
                            <img src="/img/home/nv.png" class="icon">
                        </div>
                        <div class="renzheng">官方認證女神</div>
                        <div class="baozhengjin">已繳納{{ item.margin }}保證金</div>
                        <div class="juli">
                            <span>距離</span>
                            <span class="julia">{{ item.distance }}公里</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="body-img">
                        <img :src="item.img_url" class="bodyimg">
                        <div class="body-right">
                            <div class="qianm">
                                <span>簽名: </span>
                                <span>{{ item.qianming }}</span>
                            </div>
                            
                            <div class="labels" v-if="item.labels">
                                <div class="labels-item" :class="'label'+(j+1)" v-for="(label, j) in item.labels" :key="j">{{ label }}</div>
                            </div>
                            <div class="btn" @click="getLine">
                                <img src="/img/home/line.png" class="btn-icon">
                                <span>獲取聯係方式</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			list: []
		}
	},
	methods: {
		addgo(data) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' });
			} else {
				this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
			}
		},
        getLine() {
            this.$toast("請先激活會員賬號");
        },
		getData() {
			this.$http({
				method: 'get',
				url: 'xuanfeilist'
			}).then(res => {
				this.list = res.data
			});
		}
	},
	created() {
		this.getData()
	}
};
</script>

<style lang="less" scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
}
.nav-bar {
	background: linear-gradient(90deg, #7e5678, #e6c3a1);
	height: 100px;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.van-sidebar {
	width: 180px;
}
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #f2f2f5;
}
::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
}
::v-deep .van-tabs__line {
	background-color: #7e5678;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}
.item {
    display: flex;
    box-shadow: 0px 2px 4px 1px #d5d5d5;
    width: 94%;
    margin: 20px 10px;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px;
    box-sizing: border-box;
    flex-direction: column;
}
.headimg {
	width: 160px;
    height: 160px;
	border-radius: 50%;
    margin-right: 20px;
}
.convention-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.name {
    font-size: 1rem;
    font-weight: bold;
}
.name-info {
    display: flex;
}
.icon {
    width: 40px;
    height: 40px;
    margin-left: 10px;
}
.renzheng {
    color: rgb(31, 184, 184);
    font-weight: bold;
}
.baozhengjin {
    color: rgb(160, 10, 115);
    font-weight: bold;
}
.juli {
    font-weight: bold;
    color: #353030;
}
.julia {
    margin-left: 10px;
}
.head, .body-img {
    display: flex;
}
.head {
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 20px;
    padding-bottom: 10px;
}
.bodyimg {
    width: 200px;
    height: 240px;
    border-radius: 10px;
    margin-right: 20px;
}
.btn {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    font-weight: bold;
    background: linear-gradient(90deg, #9e0a5b, #e782ce);
    padding: 10px 30px;
    border-radius: 30px;
    color: #ffffff;
    margin: 10px;
}
.btn-icon {
    width: 35px;
    height: 35px;
    margin-right: 10px;
}
.labels {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.body-right {
    display: flex;
    flex-direction: column;
}
.labels-item {
    color: #ffffff;
    margin: 10px;
    font-size: 0.7rem;
    padding: 10px 30px;
    border-radius: 30px;
}
.label1 {
    background-color: #cc148f;
}
.label2 {
    background-color: #f12525;
}
.label3 {
    background-color: #42a9e6;
}
.label4 {
    background-color: #c57122;
}
.qianm {
    font-size: 0.9rem;
    margin: 0 10px;
}
</style>
